// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/mDcYvwCtx";
import * as sharedStyle from "../css/ZZdJWJfOQ";

const cycleOrder = ["YA6ngozLD"];

const serializationHash = "framer-kWhIu"

const variantClassNames = {YA6ngozLD: "framer-v-b2buw2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, title2, width, ...props}) => { return {...props, g9o5T7uoi: title2 ?? props.g9o5T7uoi ?? "FAQ", V6jpHiTWJ: title ?? props.V6jpHiTWJ ?? "Frequently Asked Questions"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;title2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, V6jpHiTWJ, g9o5T7uoi, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YA6ngozLD", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-kWhIu", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-b2buw2", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YA6ngozLD"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-7y1zex"} data-styles-preset={"ZZdJWJfOQ"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-3ab2d8b6-b506-4374-a6e8-5ad0c59c1eff, rgb(102, 102, 102)))"}}>FAQ</motion.p></React.Fragment>} className={"framer-19qcfp1"} data-framer-name={"In this section, I’ll provide an overview of the Framer program and then we jump into setting up our file with colors, breakpoints, text styles, etc."} layoutDependency={layoutDependency} layoutId={"pPkAmp_Ao"} style={{"--extracted-r6o4lv": "var(--token-3ab2d8b6-b506-4374-a6e8-5ad0c59c1eff, rgb(102, 102, 102))", "--framer-paragraph-spacing": "0px"}} text={g9o5T7uoi} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-ja1f5m"} data-styles-preset={"mDcYvwCtx"}>Frequently Asked Questions</motion.h2></React.Fragment>} className={"framer-3o7cze"} layoutDependency={layoutDependency} layoutId={"zuK5heE6l"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={V6jpHiTWJ} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-kWhIu [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-kWhIu .framer-o5e8es { display: block; }", ".framer-kWhIu .framer-b2buw2 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 631px; }", ".framer-kWhIu .framer-19qcfp1 { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", ".framer-kWhIu .framer-3o7cze { flex: none; height: auto; overflow: visible; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-kWhIu .framer-b2buw2 { gap: 0px; } .framer-kWhIu .framer-b2buw2 > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } .framer-kWhIu .framer-b2buw2 > :first-child { margin-top: 0px; } .framer-kWhIu .framer-b2buw2 > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 67
 * @framerIntrinsicWidth 631
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"V6jpHiTWJ":"title","g9o5T7uoi":"title2"}
 * @framerImmutableVariables true
 */
const FramerpZRU0U66V: React.ComponentType<Props> = withCSS(Component, css, "framer-kWhIu") as typeof Component;
export default FramerpZRU0U66V;

FramerpZRU0U66V.displayName = "Section Heading";

FramerpZRU0U66V.defaultProps = {height: 67, width: 631};

addPropertyControls(FramerpZRU0U66V, {V6jpHiTWJ: {defaultValue: "Frequently Asked Questions", displayTextArea: false, title: "Title", type: ControlType.String}, g9o5T7uoi: {defaultValue: "FAQ", displayTextArea: false, title: "Title 2", type: ControlType.String}} as any)

addFonts(FramerpZRU0U66V, [...sharedStyle.fonts, ...sharedStyle1.fonts])